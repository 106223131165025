import { InfoConfig, protocolo } from "@/vendor/sbx/conn";

const host = InfoConfig.host();

const localHost = process.env.VUE_APP_LOCALHOST_IP;
const colantaTestingHost = process.env.VUE_APP_COLANTA_TESTING_HOST;

const URLS = {
  development: {
    apiconnection: process.env.VUE_APP_API_CONNECTION,
    apirealtime: process.env.VUE_APP_API_REALTIME,
    sysconfig: process.env.VUE_APP_SYSCONFIG,

    master: process.env.VUE_APP_MASTER,
    trade: process.env.VUE_APP_TRADE,
    config: process.env.VUE_APP_CONFIG,
    production: process.env.VUE_APP_PRODUCTION,
    planning: process.env.VUE_APP_PLANNING,

    inventory: process.env.VUE_APP_INVENTORY,
    traza: process.env.VUE_APP_TRAZA,
    mainten: process.env.VUE_APP_MAINTEN,
    pdfconn: process.env.VUE_APP_PDFCONN,

    xrundb: process.env.VUE_APP_XRUNDB,
    reports: process.env.VUE_APP_REPORTS,
    notify: process.env.VUE_APP_NOTIFY,

    costs: process.env.VUE_APP_COSTS,
    demand: process.env.VUE_APP_DEMAND,

    time_series: process.env.VUE_APP_TIMESERIES_ES,
    timeseriesES: process.env.VUE_APP_TIMESERIES_ES,
    embebbed: process.env.VUE_APP_EMBEBBED,

    //IOT MODULE
    iot: process.env.VUE_APP_IOT
  },

  production: {
    apiconnection: `${protocolo}://${host}/apiconnection`,
    apirealtime: `${protocolo}://${host}/apirealtime`,
    sysconfig: `${protocolo}://${host}/sysconfig`,

    master: `${protocolo}://${host}/master`,
    trade: `${protocolo}://${host}/trade`,
    config: `${protocolo}://${host}/config`,
    production: `${protocolo}://${host}/production`,
    planning: `${protocolo}://${host}/planning`,

    inventory: `${protocolo}://${host}/apiinventory`,
    traza: `${protocolo}://${host}/traza`,
    mainten: `${protocolo}://${host}/mainten`,
    pdfconn: `${protocolo}://${host}/pdfconn`,

    xrundb: `${protocolo}://${host}/apirundb`,
    reports: `${protocolo}://${host}/reports`,
    notify: `${protocolo}://${host}/notify`,

    costs: `${protocolo}://${host}/costs`,
    demand: `${protocolo}://${host}/demand`,

    wscosts: `ws://${host}:5000`,
    time_series: `${protocolo}://${host}/timeseries`,
    users: `${protocolo}://${host}/datacontroller`,
    services: `${protocolo}://${host}/services`,
    embebbed: `${protocolo}://${host}/embebbed`,

    time_series: `${protocolo}://${host}/timeseries`,

    timeseriesES: `${protocolo}://${host}/timeseriescontroller`,//PORT: 1851

    //IOT MODULE
    iot: `${protocolo}://${host}/iot`
  }
}

const dev = "development";
const prod = "production";

const master = URLS[prod]["master"];
const trade = URLS[prod]["trade"];
const config = URLS[prod]["config"];
const production = URLS[prod]["production"];
const planning = URLS[prod]["planning"];

const inventory = URLS[prod]["inventory"];
const traza = URLS[prod]["traza"];
const mainten = URLS[prod]["mainten"];
const pdfconn = URLS[prod]["pdfconn"];

const xrundb = URLS[prod]["xrundb"];
const reports = URLS[prod]["reports"];
const notify = URLS[prod]["notify"];

const costs = URLS[prod]["costs"];
const demand = URLS[prod]["demand"];

const wscosts = URLS[prod]["wscosts"];
const time_series = URLS[prod]["time_series"];
const timeSeriesEs = URLS[prod]["timeseriesES"];
const users = URLS[prod]["users"];
const services = URLS[prod]["services"];
const embebbed = URLS[prod]["embebbed"];

const sysconfig = URLS[prod]["sysconfig"]
const apiconnection = URLS[prod]["apiconnection"]
const apirealtime = URLS[prod]["apirealtime"]

const iot = URLS[prod]["iot"]

export {
  costs,
  wscosts,
  notify,
  embebbed,
  mainten,
  xrundb,
  traza,
  master,
  config,
  apirealtime,
  apiconnection,
  sysconfig,
  trade,
  production,
  planning,
  time_series,
  users,
  services,
  reports,
  inventory,
  pdfconn,
  demand,
  timeSeriesEs,
  iot
}

