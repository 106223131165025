<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">
    <!-- Brand demo (see demo.css) -->
    <b-navbar-nav class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <b-navbar-brand @click="modules = ''" to="/" class="app-brand demo py-0 mr-4">
      <!-- <span class="app-brand-logo"> -->

      <img :src="require('@/components/i40/imgs/logosfinal-01.png')" class="img-responsive mt-0 mb-0" width="130"
        alt="Responsive image" />
      <!-- </span> -->
      <!-- <span class="app-brand-text demo font-weight-normal ml-2">Appwork</span> -->
    </b-navbar-brand>

    <!-- Sidenav toggle (see demo.css) -->

    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2" />

      <b-navbar-nav class="align-items-lg-center">
        <!-- Search -->
        <!-- <label class="nav-item navbar-text navbar-search-box p-0 active">
          <i class="ion ion-ios-search navbar-icon align-middle"></i>
          <span class="navbar-search-input pl-2">
            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="width:200px">
          </span>
          v-if="isOptionValid('/rt') 
        </label> -->
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'rt'">
        <b-dd-item class="ml-0" to="/rt" :exact="true">{{
    $t("realTimeModuleLabel")
  }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'prd'">
        <b-dd-item class="ml-0" to="/prd" :exact="true">{{
    $t("productionLabel")
  }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'ppl'">
        <b-dd-item class="ml-0" to="/ppl" :exact="true">{{
    $t("textPlanning")
  }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'mnt'">
        <b-dd-item class="ml-0" to="/mnt" :exact="true">{{
    $t("maintenanceModuleLabel")
  }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'comm' || modules == 'cfgn'">
        <b-dd-item class="ml-0" to="/comm" :exact="true">{{
    $t("commercialModuleLabel")
  }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'invent'">
        <b-dd-item class="ml-0" to="/invent" :exact="true">{{
    $t("inventoryModuleLabel")
  }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'cst'">
        <b-dd-item class="ml-0" to="/cst" :exact="true">{{
    $t("costsModuleLabel")
  }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'sys'">
        <b-dd-item class="ml-0" to="/sys" :exact="true">{{
    $t("informationSyncBoxModuleLabel")
  }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'sys'">
        <b-dd-item v-if="check_access('admin_services', 'r')" to="/sys/task_scheduler" :exact="true">{{
    $t("scheduledTasksLabel") }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'sys'">
        <b-dd-item v-if="check_access('info_check', 'r')" to="/sys/devices" :exact="true">{{ $t("diagnosisLabel")
          }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'sys'">
        <b-dd-item v-if="check_access('info_licence', 'r')" to="/sys/infolicense" :exact="true">{{ $t("licenseLabel")
          }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav to="/rt" class="align-items-lg-center" v-if="modules == 'iot'">
        <b-dd-item class="ml-0" to="/IoT" :exact="true">IoT</b-dd-item>
      </b-navbar-nav>

      <!-- Configuracion SyncBox -->
      <!-- <b-navbar-nav icon="fas fa-info-circle" v-if="isOptionValid('/sys')">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
        <template slot="button-content">
          <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
            <span class="px-1 mr-lg-2 ml-2 ml-lg-0">Informacion SyncBox</span>
          </span>
        </template>
<b-dd-item v-if="check_access('admin_services', 'r')" to="/sys/task_scheduler" :exact="true">Tareas
  Programadas</b-dd-item>
<b-dd-item v-if="check_access('info_check', 'r')" to="/sys/devices" :exact="true">Diagnóstico</b-dd-item>
<b-dd-item v-if="check_access('info_licence', 'r')" to="/sys/infolicense" :exact="true">Licencia</b-dd-item>
</b-nav-item-dropdown>
</b-navbar-nav> -->

      <!-- HERRAMIENTAS -->
      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'rt'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->

              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("toolsLabel")
  }}</span>
            </span>
          </template>
          <!-- <b-dd-item class="ml-0" to="/btc/btc" :exact="true">Bitácora</b-dd-item> -->
          <b-dd-item class="ml-0" to="/rt/calls" :exact="true">{{
    $t("callsLabel")
  }}</b-dd-item>
          <b-dd-divider></b-dd-divider>
          <b-dd-item to="/rt/timeseries" :exact="true">{{
    $t("seriesQueryLabel")
  }}</b-dd-item>
          <b-dd-item to="/rt/rttimeseries" :exact="true">{{
    $t("realTimeSeriesLabel")
  }}</b-dd-item>
          <b-dd-item to="/rt/alarms" :exact="true">{{
    $t("alarmVisualizationLabel")
  }}</b-dd-item>
          <b-dd-item to="/rt/order_programming" :exact="true">{{
    $t("quickOrderCreate")
  }}</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- HERRAMIENTAS -->

      <!-- BITACORA -->
      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'rt'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->

              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("logbookLabel")
  }}</span>
            </span>
          </template>
          <!-- <b-dd-item class="ml-0" to="/btc/btc" :exact="true">Bitácora</b-dd-item> -->
          <b-dd-item class="ml-0" to="/btc/clst" :exact="true">{{
    $t("shiftClosureLabel")
  }}</b-dd-item>

          <b-dd-item class="ml-0" v-for="(item, index) in binnacle" :key="index" :to="`/btc/${item.code}`"
            :exact="true">{{
    item.text }}</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- BITACORA -->

      <!-- MODIFICACION DE TIEMPOS -->
      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'rt'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->

              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("timesLabel")
  }}</span>
            </span>
          </template>
          <b-dd-item v-if="check_access('mod_times', 'r')" to="/rt/edittime" :exact="true">{{
    $t("timeModificationLabel")
  }}</b-dd-item>
          <b-dd-item v-if="check_access('pend_app_times', 'r')" to="/rt/apptime" :exact="true">{{
    $t("pendingApprovalsLabel")
  }}</b-dd-item>
          <!-- <b-dd-item v-if="check_access('floor_scrap', 'r')" to="/rt/scrap" :exact="true">Historial de Cambios</b-dd-item> -->
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- MODIFICACION DE TIEMPOS -->

      <!-- INFORMES -->
      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'rt'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("reportsLabel")
  }}</span>
            </span>
          </template>
          <!-- <b-dd-item class="ml-0" to="/btc/btc" :exact="true">Bitácora</b-dd-item> -->
          <b-dd-item class="ml-0" to="/reprt/stopcodepergroup" :exact="true">{{
    $t("downtimeByGroupsLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/reprt/resumenoeedetal" :exact="true">{{
    $t("detailedOEELabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/reprt/stopcodeperworkstation" :exact="true">{{ $t("generalSummaryLabel")
            }}</b-dd-item>
          <!-- <b-dd-item class="ml-0" to="/reprt/general" :exact="true">Totales Generales</b-dd-item> -->
          <b-dd-item class="ml-0" to="/reprt/oee_planta" :exact="true">{{
    $t("oeeTrendsLabel")
  }}</b-dd-item>
          <!-- <b-dd-item class="ml-0" to="/reprt/workstationdetails" :exact="true">Detalle Centros de Trabajo</b-dd-item> -->
          <!-- <b-dd-item class="ml-0" to="/reprt/orderproduction" :exact="true">Ordenes de Producción</b-dd-item> -->
          <b-dd-item class="ml-0" to="/reprt/products" :exact="true">{{
    $t("productSearchLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/reprt/productiontime" :exact="true">{{
    $t("timeRangeProductsLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/reprt/cmp" :exact="true">{{
    $t("materialConsumptionLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/reprt/reportproduction" :exact="true">{{
    $t("reportsLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/reprt/calls" :exact="true">{{
    $t("callsLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/reprt/preshift" :exact="true">{{
    $t("preshiftLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/reprt/detailworkstation" :exact="true">{{
    $t("productionSummaryLabel")
  }}</b-dd-item>

          <b-dd-divider></b-dd-divider>
          <!-- <template slot="link-text">Mantenimiento</template> -->
          <b-dd-item class="ml-0" to="/mttoreport/disp" :exact="true">{{
    $t("workCenterAvailabilityLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mttoreport/mtbf" :exact="true">{{
    $t("mtbfByWorkCenterLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mttoreport/mttr" :exact="true">{{
    $t("mttrByWorkCenterLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mttoreport/mttrmtbf" :exact="true">{{
    $t("tbfAndMttrByWorkCenterLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mttoreport/mttrmtbfmonth" :exact="true">{{ $t("mtbfAndMttrByMonthLabel")
            }}</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- INFORMES -->

      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'prd' || modules == 'ppl'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("textDrums")
  }}</span>
            </span>
          </template>
          <!-- <b-dd-item class="ml-0" to="/btc/btc" :exact="true">Bitácora</b-dd-item> -->
          <!-- <b-dd-item class="ml-0" to="/prd/searchop" :exact="true">Detalle Orden de Producción</b-dd-item>
              <b-dd-item class="ml-0" to="/prd/detailorders" :exact="true">Ver Orden de Producción</b-dd-item>
              <b-dd-item class="ml-0" v-if="check_access('drump_production', 'r')"  to="/prd/reprint_batches" :exact="true">Reimpresion de Lotes</b-dd-item> -->
          <b-dd-item class="ml-0" v-if="check_access('drump_production', 'r')" to="/prd/showops" :exact="true">
            {{ $t("textViewOpenOrders") }}
          </b-dd-item>
          <b-dd-item class="ml-0" v-if="check_access('drump_production', 'r')" to="/prd/tacproduction" :exact="true">
            {{ $t("textProductionTAC") }}
          </b-dd-item>
          <!-- <b-dd-item class="ml-0" v-if="check_access('drump_production', 'r')"  to="/prd/productionorders" :exact="true">Tambor de Producción</b-dd-item> -->
          <b-dd-item class="ml-0" v-if="check_access('drump_commercial', 'r')" to="/prd/orders" :exact="true">
            {{ $t("textOrdersDrum") }}
          </b-dd-item>
          <b-dd-item class="ml-0" v-if="check_access('drump_purchase', 'r')" to="/prd/purchases" :exact="true">
            {{ $t("textShoppingDrum") }}
          </b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'ppl'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("planningConfigurationLabel")
  }}</span>
            </span>
          </template>
          <!-- <b-dd-item class="ml-0" to="/btc/btc" :exact="true">Bitácora</b-dd-item> -->
          <b-dd-item class="ml-0" to="/ppl/demand" :exact="true">
            {{ $t("textDemandGlider") }}
          </b-dd-item>
          <b-dd-item class="ml-0" v-if="check_access('planning_online', 'r')" to="/ppl/invonl" :exact="true">
            {{ $t("textOnlineInventory") }}
          </b-dd-item>
          <b-dd-item class="ml-0" to="/ppl/oppp" :exact="true">
            {{ $t("textResourceLoad") }}
          </b-dd-item>
          <b-dd-item class="ml-0" to="/ppl/pords" :exact="true">
            {{ $t("ordersPlanning") }}
          </b-dd-item>
          <b-dd-item class="ml-0" to="/ppl/grcapacity" :exact="true">
            {{ $t("textLoad") }}
          </b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- START QUOTATIONS -->
      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'comm' || modules == 'cfgn'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("quotationsLabel")
  }}</span>
            </span>
          </template>
          <b-dd-item class="ml-0" v-if="check_access('cot_comm', 'r')" to="/comm/cot/list" :exact="true">
            {{ $t("quotationListingLabel") }}
          </b-dd-item>
          <b-dd-item class="ml-0" v-if="check_access('new_quot_comm', 'r')" to="/comm/cot/local/0" :exact="true">
            {{ $t("newQuotationLabel") }}
          </b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- END QUOTATIONS -->

      <!-- START ORDERS -->
      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'comm' || modules == 'cfgn'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("ordersLabel")
  }}</span>
            </span>
          </template>
          <b-dd-item class="ml-0" v-if="check_access('tmb_visual_comm', 'r')" to="/comm/drump" :exact="true">
            {{ $t("commercialDrumLabel") }}
          </b-dd-item>
          <b-dd-item class="ml-0" v-if="check_access('liberar_comm', 'r')" to="/comm/rel" :exact="true">
            {{ $t("releaseOrdersLabel") }}
          </b-dd-item>
          <b-dd-item class="ml-0" v-if="check_access('appr_comm', 'r')" to="/comm/appr" :exact="true">{{
    $t("approveOrdersLabel") }}
          </b-dd-item>
          <b-dd-item class="ml-0" v-if="check_access('bill_comm', 'r')" to="/comm/cart" :exact="true">{{
    $t("portfolioLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" v-if="check_access('new_order_comm', 'r')" to="/comm/newrq/local/0" :exact="true">
            {{ $t("newOrderLabel") }}
          </b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- END ORDERS -->

      <!-- START PROGRAMMED ORDERS -->
      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'comm' || modules == 'cfgn'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("programmedOrders.textMenuOptionPrgOrders")
  }}</span>
            </span>
          </template>
          <b-dd-item class="ml-0" v-if="check_access('new_order_comm', 'r')" to="/comm/newpo/local/new" :exact="true">
            {{ $t("programmedOrders.textNewPrgOrder") }}
          </b-dd-item>
          <b-dd-item class="ml-0" v-if="check_access('new_order_comm', 'r')" to="/comm/drumpo/" :exact="true">
            {{ $t("drumProgrammedOrders.textDrumProgrammedOrder") }}
          </b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- END PROGRAMMED ORDERS -->

      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'comm' || modules == 'cfgn'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("deliveriesLabel")
  }}</span>
            </span>
          </template>
          <b-dd-item class="ml-0" to="/comm/prep" :exact="true">{{
    $t("prepareShipmentLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/comm/conf" :exact="true">{{
    $t("confirmShipmentLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/comm/inv" :exact="true">{{
    $t("viewShipmentsLabel")
  }}</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'invent'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("detailsLabel")
  }}</span>
            </span>
          </template>
          <b-dd-item class="ml-0" to="/invent/invgral" :exact="true">{{
    $t("generalInventoryLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/invent/detubq" :exact="true">{{
    $t("locationDetailsLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/invent/movs" :exact="true">{{
    $t("movementsLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/invent/kardex" :exact="true">{{
    "Kardex"
  }}</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'cst'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("analysisLabel")
  }}</span>
            </span>
          </template>
          <b-dd-item class="ml-0" to="/cst/prb" :exact="true">{{
    $t("productCostLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/cst/sbrp" :exact="true">{{
    $t("subcontractingLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/cst/bgt" :exact="true">{{
    $t("accountingBudgetLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/cst/cmr" :exact="true">{{
    $t("commercialBudgetLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/cst/prd" :exact="true">P{{ $t("productionBudgetLabel") }}</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- Configuracion Operaciones IoT -->
      <ConfigIoT v-if="modules == 'iot'"></ConfigIoT>
      <SysconfigIoT v-if="modules == 'iot'"></SysconfigIoT>
      <!-- Configuracion Operaciones IoT -->


      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'cst'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("reportsLabel")
  }}</span>
            </span>
          </template>
          <b-dd-item class="ml-0" to="/cst/cmv" :exact="true">CMV</b-dd-item>
          <b-dd-item class="ml-0" to="/cst/cecosact" :exact="true">Informe CeCos por Actividad</b-dd-item>
          <b-dd-item class="ml-0" to="/cst/mdeu" :exact="true">{{
    $t("profitMarginLabel")
  }}</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center" v-if="modules == 'mnt'">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("maintenanceModuleLabel")
  }}</span>
            </span>
          </template>
          <!-- <b-dd-item class="ml-0" to="/mnt/kanban" :exact="true">{{
            $t("generalDashboardLabel")
          }}</b-dd-item> -->
          <b-dd-item class="ml-0" to="/mnt/scheduled" :exact="true">{{
    $t("scheduledMaintenance")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mnt/newrq" :exact="true">{{
    $t("createRequestLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mnt/rqactives" :exact="true">{{
    $t("activeRequestsLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mnt/otact" :exact="true">{{
    $t("activeWorkOrdersLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mnt/otce" :exact="true">{{
    $t("pendingWorkOrdersLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mnt/auth" :exact="true">{{
    $t("pendingApprovalsLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mnt/register" :exact="true">{{
    $t("informationLoggingLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mnt/invonl" :exact="true">{{
    $t("onlineInventoryLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mnt/purch" :exact="true">{{
    $t("purchasesLabel")
  }}</b-dd-item>
          <b-dd-item class="ml-0" to="/mnt/businesses" :exact="true">Empresas</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <OptionsIoT v-if="modules == 'iot'"></OptionsIoT>


      <b-navbar-nav class="align-items-lg-center ml-auto">
        <!-- Configuracion de Control de Piso -->
        <!-- Configuracion de Control de Piso -->

        <!-- Configuracion de Control de Piso -->
        <b-navbar-nav class="align-items-lg-center" v-if="modules == 'rt'">
          <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->

                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("shopFloorControlLabel")
  }}</span>
              </span>
            </template>
            <b-dd-item v-if="check_access('floor_stopcode', 'r')" to="/rt/stopcode" :exact="true">{{
    $t("downtimeCodesLabel")
  }}</b-dd-item>
            <b-dd-item v-if="check_access('floor_root', 'r')" to="/rt/rootcause" :exact="true">{{
    $t("rootCauseCodesLabel")
  }}</b-dd-item>
            <b-dd-item v-if="check_access('floor_scrap', 'r')" to="/rt/scrap" :exact="true">{{ $t("defectCodesLabel")
              }}</b-dd-item>
            <b-dd-item v-if="check_access('floor_scrap', 'r')" to="/rt/alarms" :exact="true">{{ $t("alarmsLabel")
              }}</b-dd-item>
            <b-dd-item v-if="check_access('floor_scrap', 'r')" to="/rt/alarmcount" :exact="true">{{
    $t("counterCodesLabel")
  }}</b-dd-item>
            <b-dd-item v-if="check_access('floor_tags', 'r')" to="/rt/callers" :exact="true">{{ $t("callsLabel")
              }}</b-dd-item>
            <b-dd-item v-if="check_access('floor_tags', 'r')" to="/rt/configtags" :exact="true">{{
    $t("tagConfigurationLabel")
  }}</b-dd-item>
            <b-dd-item v-if="check_access('floor_tags', 'r')" to="/rt/oee" :exact="true">{{ $t("oeeVariablesLabel")
              }}</b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- Configuracion de Control de Piso -->

        <!-- Configuracion Operaciones -->
        <b-navbar-nav class="align-items-lg-center" v-if="modules == 'rt' || modules == 'ppl'">
          <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("textOperations")
  }}</span>
              </span>
            </template>
            <b-dd-item v-if="check_access('operations_tools', 'r')" to="/cfws/tools" :exact="true">{{
    $t("toolingsLabel")
  }}</b-dd-item>
            <b-dd-item v-if="check_access('operations_plants', 'r')" to="/cfws/plants" :exact="true">{{
    $t("plantsLabel")
  }}</b-dd-item>
            <b-dd-item v-if="check_access('operations_workstation_groups', 'r')" to="/cfws/wsgroup" :exact="true">{{
    $t("workstationGroupsLabel") }}</b-dd-item>
            <b-dd-item v-if="check_access('operations_workstation', 'r')" to="/cfws/workstations" :exact="true">{{
    $t("workCenterLabel") }}</b-dd-item>
            <b-dd-item v-if="check_access('operations_process', 'r')" class="ml-0" to="/cfsw/process" :exact="true">{{
    $t("processDefinitionLabel") }}</b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- Configuracion Operaciones -->

        <!-- Configuracion General -->
        <b-navbar-nav class="align-items-lg-center" v-if="modules == 'sys'">
          <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("generalLabel")
  }}</span>
              </span>
            </template>
            <b-dd-item v-if="check_access('admin_um', 'r')" to="/bcf/um" :exact="true">{{ $t("unitsOfMeasureLabel")
              }}</b-dd-item>
            <b-dd-item v-if="check_access('floor_stopcode', 'r')" to="/bcf/forms" :exact="true">{{
    $t("general.formsAdmin")
  }}</b-dd-item>
            <!-- <b-dd-item to="/bcf/um" :exact="true">Transacciones</b-dd-item> -->
            <b-dd-item v-if="check_access('admin_tools_type', 'r')" to="/bcf/tooltype" :exact="true">{{
    $t("toolingTypesLabel") }}</b-dd-item>
            <b-dd-item v-if="check_access('admin_workstation_type', 'r')" to="/bcf/workstation_types" :exact="true">
              {{ $t("workCenterTypesLabel") }}</b-dd-item>
            <!-- <b-dd-item to="/bcf/products_types" :exact="true">Tipos de Productos</b-dd-item> -->
            <b-dd-item v-if="check_access('admin_document_status', 'r')" to="/bcf/documentstatus" :exact="true">{{
    $t("documentStatusLabel") }}</b-dd-item>
            <b-dd-item v-if="check_access('admin_documents_types', 'r')" to="/bcf/documents_types" :exact="true">
              {{ $t("general.documentsType") }}</b-dd-item>
            <b-dd-item v-if="check_access('admin_documents_types', 'r')" to="/bcf/btctpa" :exact="true">
              {{ $t("activityLogTypesLabel") }}</b-dd-item>
            <b-dd-item v-if="check_access('admin_documents_types', 'r')" to="/bcf/mov_types" :exact="true">
              {{ $t("movementTypesLabel") }}</b-dd-item>
            <b-dd-item v-if="check_access('admin_funcitonal_area', 'r')" to="/bcf/areas" :exact="true">
              {{ $t("functionalAreasLabel") }}</b-dd-item>
            <b-dd-item v-if="check_access('admin_personal', 'r')" to="/bcf/operator" :exact="true">
              {{ $t("personnelLabel") }}</b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- Configuracion General -->

        <!-- Configuracion Costos -->
        <b-navbar-nav class="align-items-lg-center" v-if="modules == 'cst'">
          <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">Costos</span>
              </span>
            </template>
            <b-dd-item class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/syf" :exact="true">Sociedades
              Financieras</b-dd-item>
            <b-dd-item class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/clcc" :exact="true">Clase de
              CC</b-dd-item>
            <b-dd-item class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/tdc" :exact="true">Tipos de
              Cuenta</b-dd-item>
            <b-dd-item class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/puc" :exact="true">Plan de
              Cuentas</b-dd-item>
            <b-dd-item class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/mcc" :exact="true">Maestros de
              Centros de
              Costos</b-dd-item>
            <b-dd-item class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/ca" :exact="true">Clase de
              Actividad</b-dd-item>
            <b-dd-item class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/ep" :exact="true">Esquema de
              Partición</b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- Configuracion Costos -->

        <!-- Configuracion Productos -->
        <b-navbar-nav class="align-items-lg-center" v-if="modules == 'rt' ||
    modules == 'prd' ||
    modules == 'ppl' ||
    modules == 'comm' ||
    modules == 'cst' ||
    modules == 'invent'
    ">
          <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("textProducts")
  }}</span>
              </span>
            </template>
            <b-dd-item v-if="check_access('products_fammily', 'r') &&
    (modules == 'rt' ||
      modules == 'prd' ||
      modules == 'ppl' ||
      modules == 'comm' ||
      modules == 'cst' ||
      modules == 'invent')
    " to="/cfpr/families/prd" :exact="true">{{ $t("productFamilyLabel") }}</b-dd-item>
            <b-dd-item v-if="check_access('products_fammily', 'r') &&
    (modules == 'rt' ||
      modules == 'prd' ||
      modules == 'ppl' ||
      modules == 'comm' ||
      modules == 'cst' ||
      modules == 'invent')
    " to="/cfpr/lines/prd" :exact="true">{{ $t("productLinesLabel") }}</b-dd-item>
            <b-dd-item v-if="check_access('products_products', 'r') &&
    (modules == 'rt' ||
      modules == 'prd' ||
      modules == 'ppl' ||
      modules == 'comm' ||
      modules == 'cst' ||
      modules == 'invent')
    " to="/cfpr/master/checkcodes" :exact="true">Check Items</b-dd-item>
            <b-dd-item v-if="check_access('products_products', 'r') &&
    (modules == 'rt' ||
      modules == 'prd' ||
      modules == 'ppl' ||
      modules == 'comm' ||
      modules == 'cst' ||
      modules == 'invent')
    " to="/cfpr/product/prd" :exact="true">{{ $t("productsLabel") }}</b-dd-item>

            <b-dd-item to="/cfwh/warehouse" v-if="modules == 'invent'" :exact="true">{{ $t("warehousesLabel")
              }}</b-dd-item>
            <b-dd-item to="/cfwh/ubc" v-if="modules == 'invent'" :exact="true">{{ $t("locationsLabel") }}</b-dd-item>

            <!-- <sidenav-menu icon="fas fa-download">
              <template slot="link-text">Descargar</template> -->

            <b-dd-divider></b-dd-divider>
            <b-dd-item v-if="check_access('products_products', 'r') &&
    (modules == 'rt' ||
      modules == 'prd' ||
      modules == 'ppl' ||
      modules == 'comm' ||
      modules == 'cst' ||
      modules == 'invent')
    " @click.prevent="exportExcel('prd')" :exact="true">Exportar Productos (Configuracion Basica)</b-dd-item>
            <b-dd-item v-if="check_access('products_products', 'r') &&
    (modules == 'rt' ||
      modules == 'prd' ||
      modules == 'ppl' ||
      modules == 'comm' ||
      modules == 'cst' ||
      modules == 'invent')
    " @click.prevent="exportExcel('rshort')" :exact="true">Exportar Rutas (Configuracion Basica)</b-dd-item>
            <b-dd-item v-if="check_access('products_products', 'r') &&
    (modules == 'rt' ||
      modules == 'prd' ||
      modules == 'ppl' ||
      modules == 'comm' ||
      modules == 'cst' ||
      modules == 'invent')
    " @click.prevent="exportExcel('rcomplete')" :exact="true">Exportar Rutas (Configuracion
              Completa)</b-dd-item>
            <b-dd-item v-if="check_access('products_products', 'r') &&
    (modules == 'rt' ||
      modules == 'prd' ||
      modules == 'ppl' ||
      modules == 'comm' ||
      modules == 'cst' ||
      modules == 'invent')
    " @click.prevent="exportExcel('bom')" :exact="true">Exportar BOM</b-dd-item>

            <b-dd-item v-if="check_access('products_products', 'r') &&
    (modules == 'rt' ||
      modules == 'prd' ||
      modules == 'ppl' ||
      modules == 'comm' ||
      modules == 'cst' ||
      modules == 'invent')
    " to="/cfpr/import/rshort" :exact="true">Subir Rutas (Configuracion Basica)</b-dd-item>
            <!-- </sidenav-menu> -->
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- Configuracion Productos -->

        <!-- Configuracion Planeacion -->
        <b-navbar-nav class="align-items-lg-center" v-if="modules == 'ppl'">
          <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("planningCalendarLabel")
  }}</span>
              </span>
            </template>
            <b-dd-item v-if="check_access('capacity_holidays', 'r')" to="/cfpl/holidays" :exact="true">{{
    $t("holidaysLabel")
  }}</b-dd-item>
            <b-dd-item v-if="check_access('capacity_capacity', 'r')" to="/cfpl/capacity" :exact="true">{{
    $t("capacitiesLabel") }}</b-dd-item>
            <b-dd-item v-if="check_access('capacity_calendar', 'r')" to="/cfpl/calendar" :exact="true">{{
    $t("calendarLabel")
  }}</b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- Configuracion Planeacion -->

        <!-- Configuracion Matenimiento -->
        <b-navbar-nav class="align-items-lg-center" v-if="isOptionValid('/cfmnt') || isOptionValid('/mnt')">
          <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("maintenanceModuleConfigLabel")
  }}</span>
              </span>
            </template>
            <b-dd-item to="/cfmnt/groups/mnt" :exact="true">Grupo de Partes</b-dd-item>
            <b-dd-item to="/cfmnt/product/mnt" :exact="true">Registro de Partes</b-dd-item>
            <b-dd-item to="/cfpr/families/mnt" :exact="true">Familia de Partes</b-dd-item>
            <b-dd-item class="ml-0" to="/cfmnt/ra" :exact="true">Registro de Activos</b-dd-item>
            <b-dd-item class="ml-0" to="/cfmnt/ctg" :exact="true">Categorías</b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- Configuracion Matenimiento -->

        <!-- Configuracion Comercial -->
        <b-navbar-nav class="align-items-lg-center" v-if="modules == 'comm'">
          <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("commercialModuleLabel")
  }}</span>
              </span>
            </template>
            <b-dd-item v-if="check_access('general_customer', 'r')" to="/cfgn/poli" :exact="true">{{
    $t("commercialModule_Label") }}</b-dd-item>
            <b-dd-item v-if="check_access('general_customer', 'r')" to="/cfgn/customer" :exact="true">{{
    $t("customersLabel")
  }}</b-dd-item>
            <b-dd-item v-if="check_access('general_providers', 'r')" to="/cfgn/supplier" :exact="true">{{
    $t("suppliersLabel")
  }}</b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- Configuracion Comercial -->

        <!-- Configuracion Usuarios -->
        <b-navbar-nav icon="fas fa-users" v-if="modules == 'sys'">
          <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
              <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
    $t("usersLabel")
  }}</span>
              </span>
            </template>
            <b-dd-item v-if="check_access('users', 'r')" to="/users/users_list" :exact="true">{{ $t("userListLabel")
              }}</b-dd-item>
            <b-dd-item v-if="check_access('users_edit', 'r')" to="/users/users_edit" :exact="true">{{
              $t("createUserLabel")
              }}</b-dd-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">
          |
        </div>

        <b-nav-item-dropdown no-caret class="demo-navbar-notifications mr-lg-3">
          <template slot="button-content">
            <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>
            <span class="badge badge-primary badge-dot indicator" v-if="listContent.length > 0"></span>
            <span class="d-lg-none align-middle">&nbsp; Notifications</span>
          </template>

          <div class="bg-primary text-center text-white font-weight-bold p-3">
            {{ listContent.length }} Nueva Notificación
          </div>
          <b-btn class="mt-1" variant="outline-danger" block size="xs" @click="removeallalarm()"><span
              class="ion ion-md-close"></span>&nbsp; Descartar Todas las
            Notificaciones</b-btn>
          <b-list-group flush v-for="(item, index) of listContent" :key="index">
            <b-list-group-item class="media d-flex align-items-center">
              <div class="ui-icon ui-icon-sm fas fa-clipboard-list bg-secondary border-0 text-white"></div>
              <div class="media-body line-height-condenced ml-3">
                <!-- {{item}} -->
                <div class="text-dark">{{ item.title }}</div>
                <!-- <b-list-group-item  href="javascript:void(0)"> -->
                <div class="text-light small mt-1">
                  {{ item.message }}
                </div>
                <div class="text-light small mt-1">
                  {{ item.username }} {{ item.created }}

                  <b-btn variant="outline-success borderless icon-btn" class="btn-xs"
                    :to="{ path: '/btc/det/' + item.modules_id }" target="_blank"><i class="fas fa-link"></i></b-btn>
                </div>
                <!-- </b-list-group-item> -->
                <!-- <div class="row bt-2">
                  <div class="col" style="padding-right:5px;">
                    <b-btn variant="success" block size="xs" @click="messageContent=true"><span class="ion ion-md-checkmark"></span>&nbsp; Leído</b-btn>
                  </div>
                  <div class="col"  style="padding-left: 5px;">
                    <b-btn variant="danger" block size="xs" @click="messageContent=true"><span class="ion ion-md-close"></span>&nbsp; Eliminar</b-btn>
                  </div>
                </div> -->
                <b-btn variant="outline-danger" block size="xs" @click="removealarm(item)"><span
                    class="ion ion-md-close"></span>&nbsp;
                  {{ $t("deleteButtonLabel") }}</b-btn>
              </div>
            </b-list-group-item>

          </b-list-group>

          <!-- <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all notifications</a> -->
        </b-nav-item-dropdown>

        <!-- Divider -->
        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">
          |
        </div>

        <b-nav-item-dropdown class="demo-navbar-user">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
                userInfo.name + " " + userInfo.lastname
                }}</span>
            </span>
          </template>

          <!-- <b-dd-item><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</b-dd-item>
          <b-dd-item><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item>
          <b-dd-item><i class="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</b-dd-item>
          <b-dd-divider /> -->
          <b-dd-item @click="logout"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log
            Out</b-dd-item>
        </b-nav-item-dropdown>

        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">
          |
        </div>

        <b-nav-item-dropdown class="demo-navbar-user">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle text-dark">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">
                <i class="fas fa-language"></i> {{ textButton }}</span>
            </span>
          </template>
          <b-dd-item @click="onChangeLanguage('es')">{{
            code_language === "es" ? "ESPAÑOL" : "SPANISH"
            }}</b-dd-item>
          <b-dd-item @click="onChangeLanguage('en')">{{
            code_language === "es" ? "INGLÉS" : "ENGLISH"
            }}</b-dd-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { infouser } from "@/vendor/sbx/sbx-users/users";
import { infonotify } from "@/components/i40/js/notify";
import { infousers } from "@/components/i40/js/users";
import { infosysconfig } from "@/components/i40/js/sysconfig";
import { infomaster } from "@/components/i40/js/master";
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider,
} from "@/vendor/libs/sidenav";

import OptionsIoT from "./modules/optionsIoT.vue";
import ConfigIoT from "./modules/configIoT.vue";
import SysconfigIoT from "./modules/sysconfigIoT.vue";

export default {
  name: "app-layout-navbar",

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,

    OptionsIoT,
    ConfigIoT,
    SysconfigIoT
  },

  data() {
    return {
      userInfo: {},
      listContent: [],
      binnacle: [],
      modules: "",

      code_language: null,
      textButton: "Idioma",
    };
  },

  methods: {
    async exportExcel(param) {
      let datainfo = [];
      await infomaster.getcontentdownload(param).then((data) => {
        datainfo = data.data;
      });

      let data = XLSX.utils.json_to_sheet(datainfo);
      const workbook = XLSX.utils.book_new();
      const filename = param;
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    onChangeLanguage(code) {
      this.$i18n.locale = code;
      this.code_language = code;
      this.textButton = this.code_language === "es" ? "Idioma" : "Language";

      localStorage.setItem("selectedLanguage", code);
    },

    getLanguageFromLocaleStorage() {
      const language = localStorage.getItem("selectedLanguage");

      if (language) {
        if (language === "en") {
          this.textButton = "Language";
        } else {
          this.textButton = "Idioma";
        }
      }
    },

    getnotify() {
      infonotify.notifycontroller("get", "get").then((data) => {
        this.listContent = [];
        if (data.data != "") {
          //
          this.listContent = data.data;
        }
      });
    },
    removeallalarm() {
      infonotify.notifycontroller([], "delete_all").then((data) => {
        this.getnotify();
      });
    },
    removealarm(item) {
      infonotify
        .notifycontroller(item.notifications_id, "delete")
        .then((data) => {
          this.getnotify();
        });
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
    logout: function () {
      localStorage.removeItem("syncbox.cloud");
      this.$router.push(this.$route.query.redirect || "/login");
    },

    check_access(control, action) {
      return infousers.check_access(control, action);
      // return true
    },

    isOptionValid(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    validateURL() {
      this.modules = "";

      const routeToModule = {
        '/rt/': 'rt',
        '/reprt/': 'rt',
        '/prd/': 'prd',
        '/ppl/': 'ppl',
        '/cfws/': 'ppl',
        '/mnt/': 'mnt',
        '/comm/': 'comm',
        '/invent/': 'invent',
        '/cst/': 'cst',
        '/sys/': 'sys',
        '/IoT/': 'iot',
        '///': ''
      };

      const previousRoute = sessionStorage.getItem("previousRoute");

      this.modules = routeToModule[previousRoute];
    },
  },
  watch: {
    "this.$route.path": function (val) { },
  },
  created() {
    this.getLanguageFromLocaleStorage();

    this.getnotify();

    this.validateURL();

    this.$options.interval = setInterval(this.getnotify, 20000);
  },
  beforeCreate() {
    let rs = infouser.currentUser();
    if (rs != "ERROR") {
      rs.then((response) => {
        this.userInfo = response.data;
      }).catch((e) => { });
    } else {
      this.$router.push(this.$route.query.redirect || "/login");
    }

    infosysconfig.functionalarea([], "select").then((data) => {
      this.binnacle = [];
      if (data.data != "") {
        for (let index = 0; index < data.data.length; index++) {
          const element = data.data[index];
          if (element.binnacle_active)
            this.binnacle.push({
              text: element.description,
              code: element.binnacle_code,
            });
        }
      }
    });
  },
};
</script>
