/**
 * @file I18n configuration file
 * @copyright Daniel Salazar 2023
 * @mail <dsalazar@smgsoftware.com>
 */

import Vue from "vue";
import VueI18n from "vue-i18n";
import enMessages from '../../../locales/en.json'
import esMessages from '../../../locales/es.json'

Vue.use(VueI18n)

const messages = {
    en: enMessages,
    es: esMessages,
}


export const i18n = new VueI18n({
    locale: localStorage.getItem("selectedLanguage") || "es",
    messages,
})



