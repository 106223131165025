const MaintenanceRoutes = [
  {
    path: "mnt/",
    component: () => import("@/components/sbx/maintenance/mto_start.vue"),
  },
  {
    path: "mnt/kanban",
    component: () => import("@/components/sbx/maintenance/kanban"),
  },
  {
    path: "mnt/newrq",
    component: () => import("@/components/sbx/maintenance/solicitudes"),
  },
  {
    path: "mnt/auth",
    component: () => import("@/components/sbx/maintenance/authorization"),
  },
  {
    path: "mnt/rqactives",
    component: () => import("@/components/sbx/maintenance/requests_actives"),
  },
  {
    path: "mnt/register",
    component: () => import("@/components/sbx/maintenance/registro_info"),
  },
  {
    path: "mnt/otact",
    component: () => import("@/components/sbx/maintenance/ot_actives"),
  },
  {
    path: "mnt/otce",
    component: () => import("@/components/sbx/maintenance/ot_to_finish"),
  },
  {
    path: "mnt/invonl",
    component: () => import("@/components/sbx/maintenance/inv_linea"),
  },
  {
    path: "mnt/purch",
    component: () => import("@/components/sbx/maintenance/compras"),
  },
  {
    path: "mnt/scheduled",
    component: () =>
      import(
        "@/components/sbx/maintenance/scheduled-maintenance/ScheduledMaintenanceView.vue"
      ),
  },
  {
    path: "mnt/businesses",
    component: () =>
      import("@/components/sbx/maintenance/businesses/BusinessesView.vue"),
  },
];

export { MaintenanceRoutes };
