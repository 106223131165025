const PlanningRoutes = [
    {
        path: 'ppl/',
        component: () => import('@/components/sbx/planning/analysis/CommercialOrdersView')
        //component: () => import('@/components/sbx/planning/planning_start')
    },
    {
        path: 'ppl/order',
        component: () => import('@/components/sbx/controls/Info_Orders')
    },

    {
        path: 'ppl/capacity',
        component: () => import('@/components/sbx/planning/planner_prepared')
    },
    {
        path: 'ppl/grcapacity',
        component: () => import('@/components/sbx/planning/grload')
    },
    {
        path: 'ppl/plresources',
        component: () => import('@/components/sbx/planning/control_capacity')
    },
    {
        path: 'ppl/oppp',
        component: () => import('@/components/sbx/planning/control_capacity')
    },
    {
        path: 'ppl/mps/:code',
        component: () => import('@/components/sbx/planning/mps')
    },
    {
        path: 'ppl/invonl',
        component: () => import('@/components/sbx/planning/inventory_online')
    },
    {
        path: 'ppl/pords',
        component: () => import('@/components/sbx/planning/planning_ord')
    },
    {
        path: 'ppl/gantt',
        component: () => import('@/components/sbx/planning/gantt')
    },
    {
        path: 'ppl/manual_report',
        component: () => import('@/components/sbx/planning/manual_report')
    },
    {
        path: 'ppl/production_order/:code',
        component: () => import('@/components/sbx/planning/create_order_production')
        // component: () => import('@/components/sbx/planning/cr_planning_order')
    },
    {
        path: 'ppl/production_order/:code/:qty',
        component: () => import('@/components/sbx/planning/create_order_production')
        // component: () => import('@/components/sbx/planning/cr_planning_order')
    },
    {
        path: 'ppl/production_order/:code/:qty/:order',
        component: () => import('@/components/sbx/planning/create_order_production')
        // component: () => import('@/components/sbx/planning/cr_planning_order')
    },
    {
        path: 'ppl/group_planning',
        component: () => import('@/components/sbx/planning/group_planning')
        // component: () => import('@/components/sbx/planning/cr_planning_order')
    },
    {
        path: 'ppl/demand',
        component: () => import('@/components/sbx/planning/planning_demand')
    },
    {
        path: 'ppl/planner',
        component: () => import('@/components/sbx/planning/planner_prepared')
    },
    {
        path: 'ppl/listplanned',
        component: () => import('@/components/sbx/planning/list_order_planning')
    },
]

export {
    PlanningRoutes
}